import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import FAQList from '../../general/faqsList';
import InnerPageHeader from "./components/innerPageHeader";
import { scrollToElement } from "../../../../utility/utils"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../general/more-for-you";

import KudaPOSTerminalIcon from "../../../../../assets/img/more-for-you/kuda-pos-icon.inline.svg"
import InvoiceIcon from "../../../../../assets/img/more-for-you/kuda-invoicing.inline.svg"
import VirtualPOSIcon from '../../../../../assets/img/more-for-you/kuda-virtual-icon.inline.svg'

import EntryIllustration from '../../../../../assets/img/sub-account-hero-image.svg'
import EntryIllustrationBlur from "../../../../../assets/img/business/blur/sub-account-hero-blur.svg"
import DashboardIcon from "../../../../../assets/img/light-dashboard.inline.svg"
import SendGreyIcon from "../../../../../assets/img/kuda-send-grey.inline.svg"
import TransactionHistoryIcon from '../../../../../assets/img/kuda-transaction-history.inline.svg'

import organiseAcctIllustration from "../../../../../assets/img/business/sub_account/organiseAcct-illustration.svg"
import sendMoneyIllustration from "../../../../../assets/img/business/sub_account/subAcct-sendMoney-illustration.svg"
import transactHistoryIllustration from "../../../../../assets/img/business/sub_account/transaction-history-illustration.svg"

import { Img } from "react-image";


const entryContent = {
  title: (
  <span>
    Organise your finances better with <span className="color-secondary">Sub Accounts</span> on Kuda Business.
  </span>
  ),
  subtitle: "Create accounts within your main account for specific uses like recurring expenses, rent and tax remittance.",
  name: "Join Kuda Business",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const businessTopFeatures = [
  {
    icon: <DashboardIcon />,
    text: "Create up to five sub accounts to organise your finances."
  },
  {
    icon: <SendGreyIcon />,
    text: "Send money to and from sub accounts directly."
  },
  {
    icon: <TransactionHistoryIcon />,
    text: "Keep account balances and transaction histories separate."
  },
]

const organiseAccount = {
  title: "Create up to five sub accounts to organise your finances.",
  subtitle: "Putting money aside for different uses is a lot easier with multiple Kuda Business sub accounts.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Get Kuda Business",
  illustration: (
    <Img src={organiseAcctIllustration} className="kuda-business-image" />

  ),
}

const sendMoney = {
  title: "Send money to and from sub accounts directly.",
  subtitle: "Each sub account has its own account number, which means that you can use it for direct transfers like your main account!",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Get Kuda Business",
  illustration: (

    <Img src={sendMoneyIllustration} className="kuda-business-image" />

  ),
}

const transactionHistory = {
  title: "Keep account balances and transaction histories separate.",
  subtitle: "There’s never any confusion because each sub account’s balance and transaction history is separate from your main balance and transaction history.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Join Kuda Business",
  illustration: (

    <Img src={transactHistoryIllustration} className="kuda-business-image" />

  ),
}


const questions = [
  {
    heading: "What’s a sub account?",
    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16">A Kuda Business sub account is an account within your main account that you can use to put money away for specific things like recurring expenses, rent or tax remittance. It has its own unique (account) number for direct transfers.</span>

      </span>),
    index: 1
  },
  {
    heading: "How do I create a sub account?",
    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">To create a sub account, follow the steps below:
        </span> 

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Sign in to Kuda Business here, then click <b>Sub Accounts.</b></div>
            <div className="mb-3">2. Tap <b>Create An Account.</b> </div>
            <div className="mb-3"> 3. Name your sub account, then tap <b>Done.</b></div>
          </div>
        </span>

      </span>),
    index: 2
  },
  {
    heading: "How many sub accounts can I create?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
        You can create up to five sub accounts.
        </span>
      </div>),
    index: 3
  },
  {
    heading: "Can I send and receive money with a sub account?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
        Yes, you can send and receive money with a sub account because it has its own account number.
        </span>
      </div>),
    index: 4
  },

]

const moreForYou = [
  {
    icon: <KudaPOSTerminalIcon />,
    title: "POS",
    subText: `Buy a physical POS, lease it for a 
    one-time fee, or get a virtual POS to accept payments quickly.`,
    linkTo: `/en-ng/business/pos/`
  },
  {
    icon: <InvoiceIcon />,
    title: "Invoicing",
    subText: `Create and manage invoices easily, and sync your product catalogue for accurate pricing.`,
    linkTo: `/en-ng/business/invoicing/`
  },
  {
    icon: <VirtualPOSIcon />,
    title: "Virtual POS",
    subText: `Empower your salespeople to accept payments instantly at all your business locations.`,
    linkTo: `/en-ng/business/virtual-pos/`
  },

]

const SubAccounts = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (

    <Fragment>

      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<Img src={EntryIllustrationBlur} className="hero-illustration" />}
      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />
      <ImageDarkTextRightWithButton
        title={organiseAccount.title}
        subtitle={organiseAccount.subtitle}
        illustration={organiseAccount.illustration}
        name={organiseAccount.name}
        url={organiseAccount.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={sendMoney.title}
        subtitle={sendMoney.subtitle}
        illustration={sendMoney.illustration}
        name={sendMoney.name}
        url={sendMoney.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={transactionHistory.title}
        subtitle={transactionHistory.subtitle}
        illustration={transactionHistory.illustration}
        name={transactionHistory.name}
        url={transactionHistory.url}
        isExternal={true}
      />

      <FAQList title={"Sub Accounts FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou} />
      <CTABusiness />
    </Fragment>
  )
}

export default SubAccounts

